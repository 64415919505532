/* You can add global styles to this file, and also import other style files */
body {
  font-family: 'Rubik', sans-serif;
  margin: 0;
}

/*No media query for `xs` since this is the default in Bootstrap*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
}
